import React, { forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useTheme } from '../../state/themecontext';
import { IStyleFunctionOrObject, IDialogFooterStyleProps, IDialogFooterStyles } from "@fluentui/react";


interface DialogProps {
  title: string;
  message: string;
  children?: React.ReactNode;
  onClose?: () => void;
  buttonText?: string;
  footerStyle?: IStyleFunctionOrObject<IDialogFooterStyleProps, IDialogFooterStyles>;
}

export interface ErrorDialogHandle {
  handleClickOpen: () => void;
  handleClose: () => void;
}

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};


export const ErrorDialog = forwardRef<ErrorDialogHandle, DialogProps>((props, ref) => {
  const { title, message,
    children = null,
    onClose,
    buttonText = 'Ok',
    footerStyle = {},
  } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const { themeColors, theme } = useTheme();
  
  const dialogStyles = React.useMemo(() => ({
    main: {
      maxWidth: 450,
      backgroundColor: theme === 'light' ? "#fff" : "#2a2a2a",
    },
    title: {
      color: themeColors.errorDialogTitleColor,
    },
    subText: {
      color: themeColors.errorDialogMessageColor,
    },
  }), [theme, themeColors.errorDialogTitleColor, themeColors.errorDialogMessageColor]);
  
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    closeButtonAriaLabel: 'Close',
    subText: message,
    styles: { // Apply custom styles for title and subText
      title: dialogStyles.title,
      subText: dialogStyles.subText,
    },
  };

  useImperativeHandle(ref, () => ({
    handleClickOpen() {
      setDialogOpen(true);
    },
    handleClose() {
      setDialogOpen(false);
    },
  }));

  const handleClose = () => {
    setDialogOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const modalProps = React.useMemo(() => ({
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: dragOptions,
  }), [labelId, subTextId, dialogStyles]);

  return (
    <>
      <Dialog
        hidden={!dialogOpen}
        onDismiss={handleClose}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        styles={dialogStyles} // Apply custom styles to dialog
      >
        {children}
        <DialogFooter styles={footerStyle}>
          <PrimaryButton
            onClick={() => handleClose()}
            text={buttonText}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
});

ErrorDialog.defaultProps = {
  children: null,
  onClose: () => {},
  buttonText: 'Ok',
  footerStyle: {},
};
